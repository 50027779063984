export const dynamiTableStyles = {
  tableContainer:
    "tableContainer lg:w-[540px] md:w-[468px] mx-auto px-8 md:px-0 flex font-normal flex-wrap",
  tableTitle:
    "font-normal text-3xl leading-[37.5px] mx-0 mb-30 mt-20 p-0 text-center font-ProxNarrow tracking-[1px]",
  tableWrpr: "pt-20 recipeTable ",
  tableHead: "table w-full",
  tableBody: "table w-full",
}

export const recipe_nutrition = {
  tableContainer: "NutritionTable",
  tableTitle: "font-normal text-3xl leading-4 mx-0 mb-30 mt-px p-0 text-center",
}


export const newtable = {
  ...dynamiTableStyles,
  tableBody: "table w-full video-table-body",
  tableWrpr: "pt-20 recipeTable recipe-box px-25 py-15 bg-[#D9DFF0] rounded-lg mt-20 text-darkBlue",
  tableTitle: 'md:text-2xl font-ProxBold text-[18px] mb-15 md:text-left text-center',
  tableContainer: "video-section-table mx-auto px-8 md:px-0 flex font-normal flex-wrap",

}