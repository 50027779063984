import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import { dynamiTableStyles } from "../components/UI/DynamicTable/dynamiTable-tw-styles"
import DynamicTable from "../components/UI/DynamicTable/DynamicTable"
import Typography from "../components/UI/Typography/Typography"
import ImageHeroBanner from "../components/ImageHeroBanner/ImageHeroBanner"
import { TEXT_BANNER } from "../components/ImageHeroBanner/ImageHeroBanner-tw-styles"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import {
  labels,
  PRODUCTIDs,
  MEDIA_PHOTO_ID,
} from "../constants/label.constants"
import { formatPrdName, getRecipeName } from "../helpers/BuildImageCardObject"
import { GTMScript, gTagScriptConsent } from "../components/Scripts/Gtm.Head"
import { deslashify } from "../helpers/utils"
import {
  defaultVal,
  gaEventClick,
  gaImageClick,
} from "../constants/gtm.constants"

const ProductDetails = props => {
  const { pageContext } = props
  const {
    productData,
    contentfulData,
    prdSlug,
    dataLayer,
    similarRecipes,
    globalComponents,
  } = pageContext
  const sliderObj = { slidesToShowTab: 2.2, slidesToShowMobile: 1.2 }
  const { NutritionalTable } = productData || {}

  const getNutrition = nutrientData => {
    nutrientData?.rowData?.map(item => {
      item.value == labels?.valEnergy
        ? (item.value = labels?.energy)
        : item.value
      item.value == labels?.saturi ? (item.value = labels?.acid) : item.value
      item.value == labels?.Sodio ? (item.value = labels?.salt) : item.value
    })
    return nutrientData
  }

  const rowHeading = [
    labels?.tableHeading?.typicalValues,
    labels?.tableHeading?.serving,
    labels?.tableHeading?.gda,
    labels?.tableHeading?.percent,
  ]

  const [activeTab, setActiveTabe] = useState(0)

  const onChangeTabIndex = index => {
    setActiveTabe(index)
  }
  let pageWiseContentfuldata

  //mapping the specific page contentful data
  contentfulData?.filter(Boolean).map(pageData => {
    if (
      prdSlug?.toLowerCase().trim() ==
      deslashify(pageData?.slug?.toLowerCase().trim())
    ) {
      pageWiseContentfuldata = pageData
    }
  })

  const { seoData, componentProps } = pageWiseContentfuldata || {}

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: "Product Detail Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const getImageUrl = photoTypeID => {
    const filterImage = photoTypeID?.find(item => item.Type === MEDIA_PHOTO_ID)
    return filterImage?.Url || ""
  }

  const heroBannerData = buildHeroBannerData()
  const imgCardsData = similarRecipes && buildImageCardsData()

  function buildCards() {
    let cards = []
    similarRecipes?.map((data, index) => {
      if (index < 3) {
        cards.push({
          title: getRecipeName(data.RecipeName),
          variant: "Similar_Recipe_variant_product_detail_page",
          image: {
            url: getImageUrl(data.PhotoURL),
            title: data.RecipeName,
            height: 400,
            width: 400,
          },
          link: {
            url: `/recipes/${data.SeoPageName}/`,
            gaEventClass: gaImageClick,
            gaEventLabel: data.RecipeName,
          },
          gaEventClass: gaImageClick,
          gaEventLabel: data.RecipeName,
        })
      }
    })
    return cards
  }

  function buildImageCardsData() {
    let data = {
      title: labels.prdRecipeTitle,
      cardsCollection: {
        items: buildCards(),
      },
      link: {
        url: "/recipes/",
        label: "Check out all recipes",
        gaEventClass: gaEventClick,
        gaEventLabel: "Check out all recipes",
      },
    }
    return data
  }

  // Building the hero banner component data
  function buildHeroBannerData() {
    let img = productData?.ProductImage?.find(item =>
      item.type.includes("600x600")
    )
    let data = {
      heading: formatPrdName(productData?.ProductName) || "",
      description: productData?.MoodText,
      foregroundImage: {
        url: img?.url,
      },
      backLink: {
        label: "Back",
        url: "/range/",
      },
    }
    return data
  }
  return (
    <div>
      <Layout seoData={seoData} globalComponents={globalComponents}>
        <ImageHeroBanner
          {...heroBannerData}
          variant={"TEXT_BANNER"}
          eventClass="event_button_click"
        />

        <ImageCardsLayout
          {...imgCardsData}
          variant={"product_page_variant"}
          isSlider={true}
          sliderSettings={sliderObj}
          isShare={true}
        />

        {NutritionalTable?.length >= 1 && (
          <div className="">
            <Typography
              className={dynamiTableStyles?.tableTitle}
              content={labels.tabletitle}
            />
          </div>
        )}

        {NutritionalTable?.length > 1 && (
          <div className="nutritionFilter text-center">
            <span
              onClick={() => {
                onChangeTabIndex(0)
              }}
              className={activeTab == 0 ? "active" : ""}
            >
              {labels.servings100}
            </span>
            <span
              onClick={() => {
                onChangeTabIndex(1)
              }}
              className={activeTab == 1 ? "active" : ""}
            >
              {labels.servings30}
            </span>
          </div>
        )}

        {NutritionalTable && (
          <DynamicTable
            {...getNutrition(NutritionalTable[activeTab])}
            rowHeading={rowHeading}
            style={dynamiTableStyles}
            showHeading={NutritionalTable?.length > 1 ? false : true}
          />
        )}
        <div className="rangeINgredTxt sm:px-8 py-[50px] lg:w-[1170px] max-w-full text-[15px] my-[45px] text-center mx-auto">
          <h2>{labels.ingredientsText}</h2>
          <Typography
            content={productData?.IngredientsText}
            br_allowed={false}
          />
        </div>
      </Layout>
    </div>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: GTMScript }}
      />
    </>
  )
}

ProductDetails.propTypes = {
  pageContext: PropTypes.shape({
    productData: PropTypes.object,
    contentfulData: PropTypes.array,
    prdSlug: PropTypes.string,
    NutritionalTable: PropTypes.array,
    dataLayer: PropTypes.object,
    similarRecipes: PropTypes.array,
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }),
}

export default ProductDetails
