import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"
import * as styles from "./ImageHeroBanner-tw-styles"

const ImageHeroBanner = props => {
  const {
    description,
    heading,
    subHeading,
    image,
    foregroundImage,
    link,
    label,
    variant,
    backLink,
  } = props

  const style = styles[variant] || styles.defaultStyle

  const {
    bannerContent,
    imageResponsive,
    txtcontent,
    textSubHead,
    gradBox,
    txtHead,
    txtDesc,
    subTitle,
    heroLink,
    backBtn,
    imgParent,
    imgBg,
    imgWrapper,
    eventMenu,
    topNav,
  } = style
  return (
    <div className={bannerContent}>
      <div className={gradBox}></div>
      {image && <Image contentfulImage={image} className={imageResponsive} />}
        <div className={imgParent}>
          <div className={imgBg}></div>
          <div className={imgWrapper}>
          {foregroundImage && <Image
              contentfulImage={foregroundImage}
              className={imageResponsive}
            />
          }
          </div>
        </div>
      {backLink && (
        <div className={backBtn}>
          <Link {...backLink}/>
        </div>
      )}
      <div className={txtcontent}>
        {heading && <Typography className={txtHead} content={heading} />}
        {subHeading && <div className={textSubHead}>{heading}</div>}
        <div className={txtDesc}>
          {description && (
            <Typography className={subTitle} content={description} />
          )}
        </div>
        {link && (
          <Link
            {...link}
            gaEventClass={eventMenu}
            dataActionDetailPrefix={topNav}
            className={heroLink}
          >
            {label}
          </Link>
        )}
      </div>
    </div>
  )
}

ImageHeroBanner.propTypes = {
  description: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  image: PropTypes.object,
  foregroundImage: PropTypes.object,
  buttonText: PropTypes.string,
  link: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  backBtn: PropTypes.object,
  backLink: PropTypes.object,
  style: PropTypes.object,
}

export default ImageHeroBanner
