export const defaultStyle = {
  bannerContent: "relative lg:h-[450px] overflow-hidden bannerContent",
  gradBox: "lg:h-[450px] h-[324px] overflow-hidden absolute w-1/2 lg:bg-gradBgLetToRigth z-[1]",
  imageResponsive: "image w-full h-auto relative lg:static top-[82px] md:top-[70px] lg:top-[0] scale-[2.2] md:scale-[1.45] lg:scale-[1] lg:left-auto md:left-[-22%] left-[-52%]",
  txtcontent: "philyDiffTxt lg:right-[58.5vw] lg:mt-10 md:mt-[136px] mt-[165px] lg:bg-transparent bg-darkBlue lg:absolute lg:text-white  lg:top-[23%]  my-0 mx-auto px-0 lg:py-8 py-30 relative lg:w-[352px] lg:text-left text-white w-full text-center z-[2]",
  txtHead: "text-[35px] mb-7 leading-[50.75px]",
  txtDesc: "text-xl text-white lg:px-[0] lg:mt-auto leading-30 lg:w-full w-[348px] md:w-[352px] mx-auto",
  textLink: " ",
  heroLink: "px-10 text-sm tracking-wide text-darkBlue font-medium before:content-['/'] before:pr-5",
}

export const contentInRight = {
  bannerContent: "relative",
  image: "image w-full",
  txtcontent: "lg:absolute lg:text-white lg:right-[15%] lg:w-[28%] lg:bg-transparent lg:top-[23%] clear-both my-0 mx-auto px-0 py-8 relative lg:text-left text-white w-full text-center",
  txtHead: "text-4xl mb-1",
  txtDesc: "text-xl",
  textLink: " ",
  imageResponsive: "image",
}

export const RECIPE_DETAIL_HERO = {
  bannerContent: "relative lg:h-[530px] md:h-[350px] h-[425px] top-0 overflow-hidden ",
  imageResponsive: "image w-full  relative lg::top-[78px] lg:top-[-17%] md:top-[-52px] md:scale-[1.2] lg:scale-[1] lg:left-auto",
  txtcontent: "w-full lg:bg-transparent absolute lg:text-white lg:max-w-full top-[40%] lg:top-[36.5%] clear-both my-0 mx-auto px-0 lg:py-8 pt-30  lg:text-left text-white w-full text-center z-[2]",
  txtHead: "lg:w-[900px] lg:text-[50px] text-[35px] lg:leading-[55px] leading-[38px] mb-1 font-ProxExBold text-center heroShadow tracking-[1px] mx-auto",
  txtDesc: "text-xl text-white lg:pt-15 px-15 lg:px-[0] mt-20 lg:mt-auto",
  textLink: " ",
  heroLink: "buttonEffect2 watchVideo",
  backBtn: "w-full lg:bg-transparent absolute text-[15px] lg:text-white lg:max-w-full lg:top-[4%] top-0 clear-both my-0 mx-auto px-0 lg:py-8 py-30 lg:pt-0 text-white w-full text-center z-[2] heroShadow tracking-[0] backButton",
}

export const TEXT_BANNER = {
  heading: "text-3xl text-white text-center heroText mt-16 font-ProxNarrow tracking-wide",
  bannerContent: "relative",
  image: "image w-full",
  txtcontent: "range-content lg:mt-[230px] md:mt-[160px] sm:mt-[180px]",
  txtHead: "text-3xl mb-1 text-white text-center absolute lg:top-[105px] md:top-[105px] sm:top-[105px] top-[105px] left-[50%] translate-x-[-50%] translate-y-0 font-ProxNarrow tracking-[1px] w-full leading-[37.5px]",
  txtDesc: "txtDesc text-[15px] max-w-full w-[750px] leading-[30px] my-0 mx-auto lg:mt-0 text-center md:px-0 px-8 text-darkBlue text-center translate-x-[0%] translate-y-0 tracking-normal font-normal textBanner max-[480px]:mt-[190px]",
  textLink: "inline-block leading-7 relative text-white align-middle ",
  imgWrapper: "w-full flex justify-center",
  imgParent: "lg:relative lg:h-[428px] md:h-[500px] h-[300px]",
  imgBg: "bg-[#00329f] absolute top-0 left-0 w-full lg:h-full z-[-1] lg:h-[100%] md:h-[425px] sm:h-[345px] h-[345px]",
  imageResponsive: "lg:w-[600px] md:w-[600px] sm:w-[450px] w-full lg:min-h-[400px] md:min-h-[400px] sm:min-h-[300px] min-h-[280px] object-cover absolute lg:top-[170px] md:top-[170px] sm:top-[145px] top-[180px]  left-[50%] translate-x-[-50%] translate-y-0",
  linkWrpr: "",
  backBtn: "lg:bg-transparent absolute text-[15px] lg:text-white left-[50%] translate-x-[-50%] translate-y-0 lg:top-[35px] md:top-[35px] sm:top-[35px] top-[35px] my-0 mx-auto px-0 lg:py-8 md:py-8  text-white w-full text-center backButton"
}

export const HERO_BANNER = {
  bannerContent: "relative xl:min-h-[460px] bannerContent bg-[url('https://images.ctfassets.net/qc3lz96t0r4x/2QIuQDYZN3Op1qQ4i43D90/8e5c5ac813c77ce59d48d5cb35c4e1c0/Swirl_bkgd.png?fm=webp&q=80')] bg-center bg-cover",
  imageResponsive: "foregrndImg w-full w-[90%] lg:w-[40%] mx-auto py-[70px]",
  gradBox: "hidden",
  //imageResponsive: "image w-full h-auto relative lg:static top-[82px] md:top-[70px] lg:top-[0] scale-[2.2] md:scale-[1.45] lg:scale-[1] lg:left-auto md:left-[-22%] left-[-52%]",
  txtcontent: "philyDiffTxt",
  txtHead: "text-[35px] mb-7 leading-[50.75px]",
  txtDesc: "",
  textLink: " ",
  heroLink: "px-10 text-sm tracking-wide text-darkBlue font-medium before:content-['/'] before:pr-5",
}